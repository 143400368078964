
@font-face {
    font-family: 'icomoon';
    src:  url('icomoon.eot?cm1vne');
    src:  url('icomoon.eot?cm1vne#iefix') format('embedded-opentype'),
      url('icomoon.ttf?cm1vne') format('truetype'),
      url('icomoon.woff?cm1vne') format('woff'),
      url('icomoon.svg?cm1vne#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-api:before {
    content: "\e900";
    color: #535353;
  }
  .icon-Icon-awesome-info-circle:before {
    content: "\e901";
  }
  .icon-downloaad:before {
    content: "\e902";
  }
  .icon-Icon-awesome-question-circle:before {
    content: "\e903";
  }
  .icon-advance-search:before {
    content: "\e904";
  }
  .icon-instagram:before {
    content: "\e905";
  }
  .icon-twitter:before {
    content: "\e906";
  }
  .icon-youtube-logo:before {
    content: "\e907";
  }
  .icon-calendar:before {
    content: "\e908";
  }
  .icon-Profile:before {
    content: "\e909";
  }
  .icon-logout:before {
    content: "\e90a";
  }
  .icon-earth-globe-with-continents-maps:before {
    content: "\e90b";
  }
  .icon-call-center-agent-1:before {
    content: "\e90c";
  }
  .icon-Icon-ionic-md-notifications:before {
    content: "\e90d";
  }
  .icon-invoice-1:before {
    content: "\e90e";
  }
  .icon-Icon-metro-shop:before {
    content: "\e90f";
  }
  .icon-Icon-ionic-md-person:before {
    content: "\e910";
  }
  .icon-Group-1703:before {
    content: "\e911";
  }
  .icon-aperture:before {
    content: "\e912";
  }
  .icon-folder:before {
    content: "\e913";
  }
  .icon-add-solid:before {
    content: "\e914";
  }
  .icon-basket-Icons:before {
    content: "\e915";
  }
  .icon-dataprofiles:before {
    content: "\e916";
  }
  .icon-calendar1:before {
    content: "\e917";
  }
  .icon-Access-demo-Icons:before {
    content: "\e918";
  }
  .icon-Report-Icons:before {
    content: "\e919";
  }
  .icon-basket-Icons1:before {
    content: "\e91a";
  }
  .icon-dataprofiles1:before {
    content: "\e91b";
  }
  .icon-facebook-icons:before {
    content: "\e91c";
  }
  .icon-internet-icon:before {
    content: "\e91d";
  }
  .icon-linkdin-Icons:before {
    content: "\e91e";
  }
  .icon-Message-Icons:before {
    content: "\e91f";
  }
  .icon-Report-Icons1:before {
    content: "\e920";
  }
  .icon-twiter-Icons:before {
    content: "\e921";
  }
  .icon-clock:before {
    content: "\e922";
  }
  .icon-arrow-point-to-bottom:before {
    content: "\e923";
  }
  .icon-arrow-point-to-right1:before {
    content: "\e924";
  }
  .icon-question:before {
    content: "\e925";
  }
  .icon-hot:before {
    content: "\e926";
  }
  .icon-arrow-point-to-top:before {
    content: "\e927";
  }
  .icon-arrow-point-to-left:before {
    content: "\e928";
  }
  .icon-badge:before {
    content: "\e929";
  }
  .icon-upgrade:before {
    content: "\e92a";
  }
  .icon-clock1:before {
    content: "\e92b";
  }
  .icon-diagram-files:before {
    content: "\e92c";
  }
  .icon-Connect_1:before {
    content: "\e92d";
  }
  .icon-diagram:before {
    content: "\e92e";
  }
  .icon-filter:before {
    content: "\e92f";
  }
  .icon-hot1:before {
    content: "\e930";
  }
  .icon-arrow-point-to-left-white:before {
    content: "\e931";
  }
  .icon-cancel:before {
    content: "\e932";
  }
  .icon-Icon-feather-edit:before {
    content: "\e933";
  }
  .icon-half-diagram:before {
    content: "\e934";
  }
  .icon-menu:before {
    content: "\e935";
  }
  .icon-globe:before {
    content: "\e936";
  }
  .icon-profile:before {
    content: "\e937";
  }
  .icon-home:before {
    content: "\e938";
  }
  .icon-icon:before {
    content: "\e939";
  }
  .icon-arrow-point-to-right:before {
    content: "\e93a";
  }
  .icon-bar-chart:before {
    content: "\e93b";
  }
  .icon-information:before {
    content: "\e93c";
  }
  .icon-key:before {
    content: "\e93d";
  }
  .icon-cancel-black:before {
    content: "\e93e";
  }
  .icon-cancel-white:before {
    content: "\e93f";
  }
  .icon-global:before {
    content: "\e940";
  }
  .icon-hashtag:before {
    content: "\e941";
  }
  .icon-industry:before {
    content: "\e942";
  }
  .icon-package:before {
    content: "\e943";
  }
  .icon-list-document-interface-symbol:before {
    content: "\e944";
  }
  .icon-search:before {
    content: "\e945";
  }
  .icon-target:before {
    content: "\e946";
  }
  .icon-line-chart:before {
    content: "\e947";
  }
  .icon-line-graph:before {
    content: "\e948";
  }
  .icon-locked-padlock:before {
    content: "\e949";
  }
  .icon-login:before {
    content: "\e94a";
  }
  .icon-search1:before {
    content: "\e94b";
  }
  .icon-mail:before {
    content: "\e94c";
  }
  .icon-open-folder:before {
    content: "\e94d";
  }
  .icon-package1:before {
    content: "\e94e";
  }
  .icon-source:before {
    content: "\e94f";
  }
  .icon-pie-chart:before {
    content: "\e950";
  }
  .icon-refresh:before {
    content: "\e951";
  }
  .icon-right-arrow:before {
    content: "\e952";
  }
  .icon-setting:before {
    content: "\e953";
  }
  .icon-share:before {
    content: "\e954";
  }
  .icon-more:before {
    content: "\e955";
  }
  .icon-star:before {
    content: "\e956";
  }
  .icon-column-chart:before {
    content: "\e957";
  }
  .icon-folder-open1:before {
    content: "\e958";
  }
  .icon-column-chart-2:before {
    content: "\e959";
  }
  .icon-bar-chart1:before {
    content: "\e95a";
  }
  .icon-tick:before {
    content: "\e95b";
  }
  .icon-Curvey:before {
    content: "\e95c";
  }
  .icon-Icon-awesome-minus-circle:before {
    content: "\e95d";
  }
  .icon-doubleColumn:before {
    content: "\e95e";
  }
  .icon-freeColumn:before {
    content: "\e95f";
  }
  .icon-freeDoubleColumn:before {
    content: "\e960";
  }
  .icon-freeDoubleLine:before {
    content: "\e961";
  }
  .icon-liney:before {
    content: "\e962";
  }
  .icon-lineyWithCircle:before {
    content: "\e963";
  }
  .icon-question1:before {
    content: "\e964";
  }
  .icon-copy:before {
    content: "\e965";
  }
  .icon-user:before {
    content: "\e966";
  }
  .icon-shopping-cart:before {
    content: "\e967";
  }
  .icon-download:before {
    content: "\e968";
  }
  .icon-printer:before {
    content: "\e969";
  }
  .icon-Close:before {
    content: "\e96a";
  }
  .icon-uniE940:before {
    content: "\e96b";
  }
  .icon-uniE941:before {
    content: "\e96c";
  }
  .icon-sav:before {
    content: "\e96d";
  }
  .icon-pdf:before {
    content: "\e96e";
  }
  .icon-doc:before {
    content: "\e96f";
  }
  .icon-quotation-mark:before {
    content: "\e970";
  }
  .icon-boxView:before {
    content: "\e971";
  }
  .icon-copy1:before {
    content: "\e972";
  }
  .icon-danger:before {
    content: "\e973";
  }
  .icon-bookmark-outline:before {
    content: "\e974";
  }
  .icon-broken-link:before {
    content: "\e975";
  }
  .icon-Icon-ionic-md-eye:before {
    content: "\e976";
  }
  .icon-folder_files1:before {
    content: "\e977";
  }
  .icon-folder_files:before {
    content: "\e978";
  }
  .icon-follow:before {
    content: "\e979";
  }
  .icon-Icon-metro-image:before {
    content: "\e97a";
  }
  .icon-data:before {
    content: "\e97b";
  }
  .icon-data-sharing:before {
    content: "\e97c";
  }
  .icon-shop:before {
    content: "\e97d";
  }
  .icon-sorting:before {
    content: "\e97e";
  }
  .icon-credit-card-2:before {
    content: "\e97f";
  }
  .icon-shopping-cart1:before {
    content: "\e980";
  }
  .icon-delete-3:before {
    content: "\e981";
  }
  .icon-diskette:before {
    content: "\e982";
  }
  .icon-other:before {
    content: "\e983";
  }
  .icon-spss:before {
    content: "\e984";
  }
  .icon-uniE982:before {
    content: "\e985";
  }
  .icon-uniE983:before {
    content: "\e986";
  }
  .icon-word:before {
    content: "\e987";
  }
  .icon-pdf1:before {
    content: "\e988";
  }
  .icon-claim-this-page:before {
    content: "\e989";
  }
  .icon-credit-card-21:before {
    content: "\e98a";
  }
  .icon-pin:before {
    content: "\e98b";
  }
  .icon-checkbox:before {
    content: "\e98c";
  }
  .icon-report-this-page:before {
    content: "\e98d";
  }
  .icon-Icon-feather-upload:before {
    content: "\e98e";
  }
  .icon-created_admin:before {
    content: "\e98f";
  }
  .icon-created_user:before {
    content: "\e990";
  }
  .icon-succesful:before {
    content: "\e991";
  }
  .icon-radio_btn:before {
    content: "\e992";
  }
  .icon-radio_btn_deselect:before {
    content: "\e993";
  }
  .icon-linkPage:before {
    content: "\e994";
  }
  .icon-dataseticon-topic:before {
    content: "\e995";
  }
  .icon-checkkkkk:before {
    content: "\e996";
  }
  .icon-heart:before {
    content: "\e9da";
  }
  .icon-sort:before {
    content: "\ea4d";
  }
  .icon-telegram:before {
    content: "\ea95";
  }
  
  .icon-logout:before {
    content: "\e900";
  }
  .icon-follow:before {
    content: "\e901";
  }
  .icon-dataProfile:before {
    content: "\e902";
  }
  .icon-signin:before {
    content: "\e903";
  }
  .icon-account-preferences:before {
    content: "\e904";
  }
  .icon-check-badge:before {
    content: "\e905";
  }
  .icon-dataProfiles:before {
    content: "\e906";
  }
  .icon-question-mark-circle:before {
    content: "\e907";
  }
  .icon-file:before {
    content: "\e908";
  }
  .icon-user:before {
    content: "\e909";
  }
  .icon-surveys:before {
    content: "\e90a";
  }
  .icon-Individuals:before {
    content: "\e90b";
  }
  .icon-vendors:before {
    content: "\e90c";
  }
  .icon-Data-projectIcon:before {
    content: "\e90d";
  }
  .icon-edit:before {
    content: "\e90e";
  }
  .icon-Union:before {
    content: "\e90f";
  }
  .icon-free:before {
    content: "\e910";
  }
  .icon-Group_5:before {
    content: "\e911";
  }
  .icon-AccesstoGroups:before {
    content: "\e912";
  }
  .icon-close:before {
    content: "\e900";
  }