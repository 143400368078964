@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./assets/fonts/Inter/inter.css");
/* @import url("./assets/fonts/icomoon/style.css"); */



body {
  margin: 0;  
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgba(26, 93, 188, 0.049)!important */
  background-color: #f4f4f4!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.css-16msolw-menu{
  background-color: aqua;
}


.bg-mainblue {
  --tw-bg-opacity: 1!important;
  background-color: rgb(26 93 188/var(--tw-bg-opacity))!important
}

#rc-anchor-alert, .rc-anchor-alert {
  display: none !important;
  color: #000 !important;
}

.rc-anchor-normal {
  height: 74px;
  width: 100% !important;
}

#rc-anchor-alert,.rc-anchor-alert {
  color: rgb(68, 0, 255) !important;
  font-size: 9px;
  margin: 2px;
  position: absolute;
  top: 0
}



.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between !important; 
  transition-property: transform;
  transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
  box-sizing: content-box;
}