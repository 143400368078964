@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("assets/fonts/icomoon.css");



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.lineClamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}





.homePageHeader header {
  background: white;
  box-shadow: none;
}

.homePageHeader .wrapper {
  max-width: 1300px;
}

.panelHeader .sizeHandler {
  max-width: none;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* height: 3rem; */
}

.homePageHeader .sizeHandler {
  max-width: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.homePageHeader .SignunButton {
  background-color: #5459d0;
  padding: 0.75rem 1.5rem;
}

.homePageHeader .mainList {
  display: inline-block;
  padding: 1.5rem 2rem;
  border-radius: 250px;
  background-color: #f5f5f5;
}

.homePageHeader .mainList li {
  margin-right: 1rem;
}

.homePageHeader {
  margin-left: auto;
}

.homePageHeader header .logo {
  margin-right: 2rem;
}


.panelHeader header ul.mainMenu li:hover {
  /* background: #f5f7fd24; */
  /* border: 1px solid #D3D3D5; */
  color: #4B96FF;
  /* border-radius: 15px 15px 0 0; */
}

.panelHeader {
  z-index: 99;
  position: relative;
} 

.panelHeader header {
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  padding: 0;
  background: #333333;
}
.panelHeader .sizeHandler {
  max-width: none;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* height: 3rem; */
}
.panelHeader {
  /* margin-bottom: 13rem; */
}


.mobileAccountDetailsIcon {
  transition: all ease 0.3s;
  margin-left: auto;
}

.mobileAccountDetailsIconOpen {
  transform: rotate(-90deg);
}

.mobileAccountDetailsIconClose {
  transform: rotate(0deg);
}

.mobileAccountDetailsIcon {
  transition: all ease 0.3s;
  margin-left: auto;
}

.mobileAccountDetailsIconOpen {
  transform: rotate(-90deg);
}

.mobileAccountDetailsIconClose {
  transform: rotate(0deg);
}

.MobileLogout {
  display: flex;
  align-items: center;
  padding: 0.8rem;
  font-size: 16px;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.wrapper {
  max-width: 1024px;
  margin: 0 auto;
}
/* header,
header .wrapper {
  height: 60px;
  line-height: 60px;
} */

header .wrapper {
  overflow: initial;
  text-align: right;
}

.normalColor {
  color: #535353 !important;
}


.sizeHandler {
  /* max-width: 80vw; */
  margin: 0 auto;
}


@media only screen and (max-width: 720px) {


  .wrapper {
    max-width: 1300px;
  }

  .sizeHandler {
    max-width: none;
  }


}
/* .height5 {
  height: 5rem !important;
} */

.HeaderFirstRow {
  width: 100%;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1023px) {


  .HeaderFirstRow {
    width: 15%;
  }


  .HeaderFirstRow .myAccount {
    display: none;
  }

 
}

.mobileMenuBtn {
  font-size: 24px;
  margin-left: 1rem;
}

.rightMargin {
  margin-right: 1rem;
}

@media screen and (min-width: 1023px) {
  .vendorFileInfoMobileRaing {
    display: none;
  }

  .HeaderFirstRow {
    width: 15%;
  }

  .HeaderSecondRow {
    display: none;
  }

  .HeaderThirdRow {
    display: none;
  }

  .cancelMobileNarrowBox {
    display: none;
  }

  .mobileSearchBtn {
    display: none;
  }

  .HeaderFirstRow .myAccount {
    display: none;
  }

  .mobileHeaderTools {
    display: none;
  }

  .disableDesktop {
    display: none;
  }

  .basicInfoFirtRow {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}



.panelHeader {
  z-index: 99;
  position: relative;
}

.bg-mainblue {
  --tw-bg-opacity: 1!important;
  background-color: rgb(26 93 188/var(--tw-bg-opacity))!important;
}
*, :after, :before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}


.panelHeader header{
  box-shadow: 0 3px 6px rgba(0,0,0,.16);
  background: #333;
}

/* .clearfix{
  box-shadow: 0 3px 6px rgba(0,0,0,.16) !important;
  padding: 0 !important;
  background: #333 !important;
} */


header {
  box-shadow: 0 3px 6px rgba(0,0,0,.16);
  padding: 1rem 0;
}
header, header .wrapper {
  height: auto;
  line-height: normal;
}
header, header.down {
  box-shadow: 0 0 1px rgba(0,0,0,.2), 0 0 5px rgba(0,0,0,.2);
}
header {
  background: #fff;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
}


[dir=ltr] header .wrapper {
  text-align: right;
}

header, header .wrapper {
  height: auto;
  line-height: normal;
}
header .wrapper {
  overflow: visible;
  overflow: initial;
}
.wrapper {
  max-width: 80vw;
}
.normalColor {
  color: #535353!important;
}
.wrapper {
  max-width: 1024px;
  margin: 0 auto;
}


.homePageHeader .sizeHandler, .panelHeader .sizeHandler {
  max-width: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

/* .panelHeader .sizeHandler {
  padding: 0;
  height: 3rem;
} */
.marginManage, .sizeHandler {
  margin: 0 auto;
}
.sizeHandler {
  /* max-width: 80vw; */
}
/* .height5 {
  height: 5rem!important;
} */

@media screen and (min-width: 1023px){
.HeaderFirstRow .myAccount, .HeaderSecondRow, .HeaderThirdRow, .cancelMobileNarrowBox, .disableDesktop, .mobileHeaderTools, .mobileSearchBtn {
    display: none;
}}

/* .HeaderSecondRow {
    padding-top: 1rem;
} */
.HeaderFirstRow, .HeaderSecondRow {
    width: 100%;
    display: flex;
    align-items: center;
}

.gray_header_color {
  background: #424245;
}

@media screen and (min-width: 1023px){
.HeaderFirstRow .myAccount, .HeaderSecondRow, .HeaderThirdRow, .cancelMobileNarrowBox, .disableDesktop, .mobileHeaderTools, .mobileSearchBtn {
    display: none;
}}


.flexSpace {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.tempDisable {
  display: none!important;
}
.fullWidth {
  width: 100%;
}



.homePageHeader .sizeHandler, .panelHeader .sizeHandler {
  max-width: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

/* .panelHeader .sizeHandler {
  padding: 0;
  height: 3rem !important;
} */
.marginManage, .sizeHandler {
  margin: 0 auto;
}
.sizeHandler {
  /* width: 80vw !important; */
}
/* .height5 {
  height: 5rem!important;
} */

/* .headerSizeHandler {
  width: 80vw !important;
  background-color: #4B96FF;
} */


.homePageHeader .sizeHandler, .panelHeader .sizeHandler {
  max-width: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

[dir=ltr] .mr-2 {
  margin-right: 0.5rem!important;
}

[dir=ltr] .mr-2, [dir=ltr] .mx-2 {
  margin-right: 0.5rem!important;
}
@media screen and (min-width: 1023px)
{
  .HeaderFirstRow {
    width: 15%;
  }
}
.HeaderFirstRow, .HeaderSecondRow {
  width: 100%;
  display: flex;
  align-items: center;
}
.justify-between {
  justify-content: space-between!important;
}
.w-\[20\%\] {
  width: 20%!important;
}

@media screen and (min-width: 1023px){
.HeaderFirstRow .myAccount, .HeaderSecondRow, .HeaderThirdRow, .cancelMobileNarrowBox, .disableDesktop, .mobileHeaderTools, .mobileSearchBtn {
    display: none;
}}

[dir=ltr] .mr-5 {
  margin-right: 1.25rem!important;
}
[dir=ltr] .mr-5, [dir=ltr] .mx-5 {
  margin-right: 3rem!important;
}
.desktopHeaderSecondRow {
  width: 65%;
  display: flex;
  align-items: center;
}
.\!w-\[800px\] {
  width: 800px!important;
}


@media screen and (min-width: 1023px){
.HeaderFirstRow .myAccount, .HeaderSecondRow, .HeaderThirdRow, .cancelMobileNarrowBox, .disableDesktop, .mobileHeaderTools, .mobileSearchBtn {
    display: none;
}}
/* .HeaderSecondRow {
    padding-top: 1rem;
} */
.HeaderFirstRow, .HeaderSecondRow {
    width: 100%;
    display: flex;
    align-items: center;
}

.HeaderSecondRow .Search {
  width: 100%;
}

.HeaderSecondRow .Search .form {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 94%;
  height: 35px;
  border-radius: 8px;
  border: 1px solid #d3d3d5;
  color: #d3d3d5;
  transition: border-color .25s cubic-bezier(.65,.05,.36,1);
}

@media screen and (min-width: 1023px){
.HeaderFirstRow .myAccount, .HeaderSecondRow, .HeaderThirdRow, .cancelMobileNarrowBox, .disableDesktop, .mobileHeaderTools, .mobileSearchBtn {
    display: none;
}}
.HeaderThirdRow {
    display: flex;
    align-items: center;
    overflow-x: scroll;
    width: 100vw;
    height: 3rem;
}


@media screen and (min-width: 1023px){
.HeaderFirstRow .myAccount, .HeaderSecondRow, .HeaderThirdRow, .cancelMobileNarrowBox, .disableDesktop, .mobileHeaderTools, .mobileSearchBtn {
    display: none;
}}

.HeaderFirstRow {
  width: 100%;
  display: flex;
  align-items: center;
}

.HeaderFirstRow {
  width: 15%;
}






@media screen and (max-width: 1023px) {

  header,
  header .wrapper {
  }



  header .wrapper:last-child {
    display: none;
  }

  

  
 
  .myAccountMobile {
    padding: 4px;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  }
 
  

}



.desktopHeaderSecondRow .Search .form {
  width: 95%;
  display: flex;
  align-items: center;
  height: 35px;
  border-radius: 8px;
  border: 1px solid #d3d3d5;
  color: #d3d3d5;
  transition: border-color .25s cubic-bezier(.65,.05,.36,1);
}

.grow {
  flex-grow: 1!important;
}




[dir=ltr] .desktopHeaderSecondRow .Search .form input {
  padding-left: 0.5rem;
}
.desktopHeaderSecondRow .Search .form input {
  width: 100%;
  height: 100%;
  border: 0;
  outline: none;
  background: transparent;
  font-size: 16px;
  font-weight: 400;
  caret-color: var(--mainColor);
}




textarea:focus, .forminput:focus{
  outline: none;
  border: none;
  outline-style: none;
}


.advancedSearchWrapper {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 0.5rem;
  align-items: center;
  cursor: pointer;
}

.desktopHeaderSecondRow .Search {
  width: 90%;
}

.garage-title {
  clear: both;
  display: inline-block;
  /* overflow: hidden; */
  white-space: nowrap;
}

#rc-anchor-alert, .rc-anchor-alert {
  display: none !important;
  color: #000 !important;
}


.rc-anchor-normal {
  height: 74px;
  width: 100% !important;
}

#rc-anchor-alert,.rc-anchor-alert {
  color: rgb(68, 0, 255) !important;
  font-size: 9px;
  margin: 2px;
  position: absolute;
  top: 0
}


.myAccount {
  white-space: nowrap;
  line-height: 20px;
  display: inline-block;
  color: #d3d3d5;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,.16);
  position: relative;
  z-index: 99;
  border: 1px solid #d3d3d5;
}

.myAccountButton {
  /* border-radius: 5px; */
  color: #d3d3d5;
  font-size: 18px;
  font-weight: 300;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
}



.mobileMenuContainer {
  width: 100vw;
  height: 100vh;
  /* background: #fff; */
  padding-top: 5rem;
  padding: 0 1rem;
  padding-bottom: 10rem;
  overflow-y: auto;
  position: fixed;
  top: 4rem;
  z-index: 99;
  transition: all ease 0.3s;
}

.mobileMenuContainerOpen {
  left: 0;
}

.mobileMenuContainerClose {
  left: -101vw;
}

.mobileMenuContainer>.Account>div {
  display: flex;
  align-items: center;
  padding: 0.8rem;
  font-size: 16px;
}

.mobileMenuContainer>.Account>ul.mobileAccountDetails.open {
  transition: transform 0.5s;
  transition: max-height 0.2s;
  overflow: hidden !important;
  height: auto;
  transform: scaleY(1);
  max-height: 600px;
}

.mobileMenuContainer>.Account>ul.mobileAccountDetails.close {
  transition: transform 0.5s;
  transition: max-height 0.2s;
  overflow: hidden !important;
  transform: scaleY(0);
  max-height: 0;
}

.mobileMenuContainer>.Account {
  border-bottom: 1px solid #d0d0d0;
}

.mobileMenuContainer>.Account>ul {
  transform-origin: top;
}

.mobileMenuContainer>.Account>ul>li {
  display: flex;
  align-items: center;
  padding: 0.8rem 0.8rem 0.8rem 2.8rem;
  font-size: 16px;
}

.mobileMenuContainer>.Account>ul>li.active {
  border-right: 5px solid var(--mainColor);
  background: var(--mainColor) 1c;
}

.mobileMenuContainer>.Account>ul>li.active>span {
  color: var(--mainColor);
}

.mobileMenuContainer>.Account>ul>li.active>div {
  color: var(--mainColor);
}

.mobileMenuContainer>ul {
  border-bottom: 1px solid #d0d0d0;
}

.mobileMenuContainer>ul>li {
  display: flex;
  align-items: center;
  padding: 0.8rem;
  font-size: 16px;
  width: 100%;
}

.mobileMenuContainer>ul>li.active {
  border-left: 5px solid var(--mainColor);
  background: var(--mainColor) 1c;
}

.mobileMenuContainer>ul>li.active>span {
  color: var(--mainColor);
}

.mobileMenuContainer>ul>li.active>div {
  color: var(--mainColor);
}

.mobileMenuContainer>ul>li>span.icon {
  font-size: 18px;
  font-weight: 400;
}

.mobileMenuContainer>ul>li>div {
  font-size: 14px;
  font-weight: 400;
}

.mobileMenuContainer .icon {
  margin-right: 1rem;
}


.mobileMenuContainer {
  width: 100vw;
  height: 100vh;
  /* background: #fff; */
  padding-top: 5rem;
  padding: 0 1rem;
  padding-bottom: 10rem;
  overflow-y: auto;
  position: fixed;
  top: 0;
  z-index: 99;
  transition: all ease 0.3s;
}

.mobileMenuContainerOpen {
  left: 0;
}

.mobileMenuContainerClose {
  left: -101vw;
}

.mobileMenuContainer>.Account>div {
  display: flex;
  align-items: center;
  padding: 0.8rem;
  font-size: 16px;
}

.mobileMenuContainer>.Account>ul.mobileAccountDetails.open {
  transition: transform 0.5s;
  transition: max-height 0.2s;
  overflow: hidden !important;
  height: auto;
  transform: scaleY(1);
  max-height: 600px;
}

.mobileMenuContainer>.Account>ul.mobileAccountDetails.close {
  transition: transform 0.5s;
  transition: max-height 0.2s;
  overflow: hidden !important;
  transform: scaleY(0);
  max-height: 0;
}

.mobileMenuContainer>.Account {
  border-bottom: 1px solid #d0d0d0;
}

.mobileMenuContainer>.Account>ul {
  transform-origin: top;
}

.mobileMenuContainer>.Account>ul>li {
  display: flex;
  align-items: center;
  padding: 0.8rem 0.8rem 0.8rem 2.8rem;
  font-size: 16px;
}

.mobileMenuContainer>.Account>ul>li.active {
  border-right: 5px solid var(--mainColor);
  background: var(--mainColor) 1c;
}

.mobileMenuContainer>.Account>ul>li.active>span {
  color: var(--mainColor);
}

.mobileMenuContainer>.Account>ul>li.active>div {
  color: var(--mainColor);
}

.mobileMenuContainer>ul {
  border-bottom: 1px solid #d0d0d0;
}

.mobileMenuContainer>ul>li {
  display: flex;
  align-items: center;
  padding: 0.8rem;
  font-size: 16px;
  width: 100%;
}

.mobileMenuContainer>ul>li.active {
  border-left: 5px solid var(--mainColor);
  background: var(--mainColor) 1c;
}

.mobileMenuContainer>ul>li.active>span {
  color: var(--mainColor);
}

.mobileMenuContainer>ul>li.active>div {
  color: var(--mainColor);
}

.mobileMenuContainer>ul>li>span.icon {
  font-size: 18px;
  font-weight: 400;
}

.mobileMenuContainer>ul>li>div {
  font-size: 14px;
  font-weight: 400;
}

.mobileMenuContainer .icon {
  margin-right: 1rem;
}